// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animated-films-js": () => import("./../../../src/pages/animated-films.js" /* webpackChunkName: "component---src-pages-animated-films-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-book-template-js": () => import("./../../../src/templates/BookTemplate.js" /* webpackChunkName: "component---src-templates-book-template-js" */),
  "component---src-templates-books-template-js": () => import("./../../../src/templates/BooksTemplate.js" /* webpackChunkName: "component---src-templates-books-template-js" */)
}

